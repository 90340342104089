const get_url = () => {
  if (window.location.hostname.includes("platform.actglobaltrial.com")) {
    if (window.location.hostname.includes("test.platform.actglobaltrial.com")) {
      return "https://testapi.platform.actglobaltrial.com/v1";
    } else if (
      window.location.hostname.includes("training.platform.actglobaltrial.com")
    ) {
      return "https://api.training.platform.actglobaltrial.com/v1";
    } else if (
      window.location.hostname.includes("qa.platform.actglobaltrial.com")
    ) {
      return "https://api.qa.platform.actglobaltrial.com/v1";
    } else {
      return "https://api.platform.actglobaltrial.com/v1";
    }
  } else if (window.location.hostname.includes("sctgtrials.com")) {
    if (window.location.hostname.includes("test.platform.sctgtrials.com")) {
      return "https://testapi.platform.sctgtrials.com/v1";
    } else {
      return "https://api.platform.actglobaltrial.com/v1";
    }
  } else {
    return "http://127.0.0.1:3001/v1";
  }
};

export const api_url = get_url();
