import React, { useState, useEffect } from "react";
import styles from "./select.module.css";
import { Checkbox } from "@mui/material";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import { cloneDeep } from "lodash";

let tempStorage = null;

export default function Select(props) {
  const {
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    crfStatus,
    read_only,
    multi_entry_crf_id,
  } = props;

  const [localValueArray, setLocalValueArray] = useState([]);
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  useEffect(() => {
    if (
      field.value_obj &&
      field.value_obj.value &&
      field.value_obj.value.constructor === String
    ) {
      try {
        setLocalValueArray(JSON.parse(field.value_obj.value));
      } catch (err) {}
    } else {
      setLocalValueArray([]);
    }
  }, [field, multi_entry_crf_id, crf_id]);

  const commonUpdateValueActions = (key, action_is_add) => {
    let obj = {
      ...field.value_obj,
    };
    obj.value_name = field.value_name;
    let newValue = [...localValueArray];
    if (action_is_add) {
      newValue.push(key);
    } else {
      newValue = newValue.filter((v) => v !== key);
    }
    obj.value = JSON.stringify(newValue);
    obj.currentValueDisplay = options
      .filter((o) => newValue.includes(o.value))
      .map((o) => o.label)
      .join(", ");
    onUpdate(obj);
    setLocalValueArray(newValue);
  };

  const updateValue = (key, action_is_add) => {
    if (crfStatus === "data_complete") {
      tempStorage = { key, action_is_add };
      setShowingCompleteChangeModal(true);
      return;
    }
    commonUpdateValueActions(key, action_is_add);
  };

  const acceptTempStorage = () => {
    const { key, action_is_add } = tempStorage;
    commonUpdateValueActions(key, action_is_add);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };
  const currentValueDisplay = options
    .filter((o) => localValueArray.includes(o.value))
    .map((o) => o.label)
    .join(", ");

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles["left-split"]}>
          <NotificationWidget
            {...props}
            currentValueDisplay={currentValueDisplay}
          />
        </div>
        {read_only || field.read_only ? (
          <div>{currentValueDisplay || ""}</div>
        ) : (
          <div className={styles["right-split"]}>
            {options.map((o) => (
              <div
                key={o.value}
                className={styles.checkbox_row}
                onClick={() => {
                  updateValue(o.value, !localValueArray.includes(o.value));
                }}
              >
                <Checkbox
                  key={o.value}
                  checked={localValueArray.includes(o.value)}
                  className={styles.checkbox}
                  sx={{
                    padding: "0px",
                    marginRight: "6px",
                  }}
                  // onChange={(e) => updateValue(o.value, e.target.checked)}
                />
                {o.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={() => setShowingCompleteChangeModal(false)}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
