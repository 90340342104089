import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import styles from "../date_time.module.css";
import { pad_time_value, parseTime } from "util/ui";

export default function DateTimeUI(props) {
  const {
    key_id,
    field,
    onUpdate,
    date_position = "value",
    date_position_name = "value_name",
    time_position = "value2",
    time_position_name = "value2_name",
    read_only,
    crfStatus,
    validation = {},
    setParentFieldError,
    item_specific_error_validator,
  } = props;

  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [date, setDate] = useState(
    (field && field.value_obj && field.value_obj[date_position]) || null,
  );
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const [valueObj, setValueObj] = useState((field && field.value_obj) || {});
  const [hoursHasChanged, setHoursHasChanged] = useState(null);
  const [datePickerError, setDatePickerError] = useState(false);
  const [hourError, setHourError] = useState(false);
  const [minError, setMinError] = useState(false);

  const updateValue = (key, value) => {
    let obj = {};
    obj[date_position_name] = field[date_position_name];
    obj[time_position_name] = field[time_position_name];
    if (key === "date") {
      obj[date_position] = value.format("yyyy-MM-DD");
      obj[time_position] = hours && minutes ? `${hours}:${minutes}` : "";
    } else {
      obj[date_position] = date;
    }
    if (key === "hours" && minutes) {
      obj[time_position] = `${value}:${minutes}`;
      obj[date_position] = date;
    }
    if (key === "minutes" && hours) {
      obj[time_position] = `${hours}:${value}`;
      obj[date_position] = date;
    }
    setValueObj(obj);

    if (key === "date") {
      onUpdate(obj);
    } else if (
      // (obj[date_position] && !obj[time_position]) ||
      RegExp(/[0-9]{2}:[0-9]{2}/).test(obj[time_position])
    ) {
      onUpdate(obj);
    }
  };

  const onKeyDownHours = (key) => {
    if (key.keyCode === 13) {
      hoursRef.current.blur();
    }
  };
  const onKeyDownMinutes = (key) => {
    if (key.keyCode === 13) {
      minutesRef.current.blur();
    }
  };

  const onFocusHours = (event) => {};

  useEffect(() => {
    if (
      hours &&
      hours.length === 2 &&
      !(read_only || field.read_only) &&
      crfStatus !== "data_complete"
    )
      minutesRef.current.focus();
  }, [hoursHasChanged]);

  useEffect(() => {
    if (field.value_obj && field.value_obj[date_position]) {
      setDate(field.value_obj[date_position]);
    } else {
      setDate(null);
    }
    //the logic below of comparing for String is to pass if we have defined the value as "" vs does not exist
    if (
      field.value_obj &&
      field.value_obj[time_position]?.constructor === String
    ) {
      let time = field.value_obj[time_position];
      if (time.constructor === String) {
        time = time.split(":");
        if (time.length === 2) {
          setHours(time[0]);
          setMinutes(time[1]);
        }
      }
    } else {
      setHours("");
      setMinutes("");
    }
  }, [field.value_obj]);

  const is_error = (value) => {
    if (value.length === 0) {
      return false;
    } else {
      let local_validation = () => !/^[0-9]{1,12}$/.test(value);
      let error = local_validation();
      if (
        item_specific_error_validator &&
        item_specific_error_validator.constructor === Function
      ) {
        error = local_validation() || item_specific_error_validator(value);
      }

      // setParentFieldError && setParentFieldError(key, error);
      return error;
    }
  };

  const aggregateErrorsAndSetParent = (error, datetime = null) => {
    if (setParentFieldError) {
      if (error) {
        setParentFieldError(key_id, true);
      } else {
        //the following logic looks a bit funky because the datepicker doesn't set it's error on change the same way that the textfields do
        //so we need to check the error inline, vs relying on the error state being checked after it has been updated
        if (
          ((datetime && !error) || !datePickerError) &&
          !hourError &&
          !minError
        ) {
          setParentFieldError(key_id, false);
        } else {
          setParentFieldError(key_id, true);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className={styles.datetime_ui_wrapper}>
        {read_only || field.read_only ? (
          <div>{date || "---"}</div>
        ) : (
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="YYYY / MM / DD"
                value={(date && moment(date, "yyyy-MM-DD")) || null}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px 10px",
                    width: "120px",
                  },
                  "& .MuiInputAdornment-root": { marginLeft: 0 },
                  "& .MuiButtonBase-root": { paddingLeft: 0 },
                }}
                onChange={(value) => {
                  if (value && value.isValid()) {
                    setDate(value.format("yyyy-MM-DD"));
                  }
                  updateValue("date", value);
                }}
                disableFuture={validation.disableFuture}
                onError={(error) => {
                  setDatePickerError(error);
                  aggregateErrorsAndSetParent(error, "datetime");
                }}
              />
            </LocalizationProvider>
          </div>
        )}
        {read_only || field.read_only ? (
          <div
            style={{ marginLeft: 10 }}
          >{`${`${hours}:${minutes}` || "---"}`}</div>
        ) : (
          <React.Fragment>
            <div style={{ marginLeft: 14 }}>
              <TextField
                type="number"
                inputRef={hoursRef}
                placeholder="HH"
                value={hours}
                onChange={(e) => {
                  setHoursHasChanged(Math.random());
                  setHours(e.target.value);
                  if (parseInt(e.target.value) >= 24) {
                    setHourError(true);
                  } else {
                    setHourError(false);
                  }
                }}
                onKeyDown={onKeyDownHours}
                inputProps={{ maxLength: 2 }}
                autoComplete="off"
                onFocus={onFocusHours}
                onBlur={() => {
                  if (hours === "") {
                    setHours(parseTime(valueObj[time_position]).hours);
                  } else {
                    let display = pad_time_value(hours);
                    setHours(display);
                    updateValue("hours", display);
                    aggregateErrorsAndSetParent(hourError);
                  }
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "30px",
                  },
                }}
                error={hours >= 24}
              />
            </div>
            <div className={styles.colon}>:</div>
            <div>
              <TextField
                type="number"
                inputRef={minutesRef}
                placeholder="MM"
                value={minutes}
                onChange={(e) => {
                  setMinutes(e.target.value);
                  if (parseInt(e.target.value) >= 60) {
                    setMinError(true);
                  } else {
                    setMinError(false);
                  }
                }}
                onKeyDown={onKeyDownMinutes}
                onBlur={() => {
                  if (minutes === "") {
                    setMinutes(parseTime(valueObj[time_position]).minutes);
                  } else {
                    let display = pad_time_value(minutes);
                    setMinutes(display);
                    updateValue("minutes", display);
                    aggregateErrorsAndSetParent(minError);
                  }
                }}
                inputProps={{ maxLength: 2 }}
                autoComplete="off"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "30px",
                  },
                }}
                error={minutes >= 60}
              />
            </div>
            <div className={styles.input_description}>(24h clock)</div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
