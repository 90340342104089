import React, { useState, useRef, useEffect } from "react";
import styles from "./date.module.css";
import { TextField } from "@mui/material";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import YearUI from "./components/year_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import { CheckIcon } from "ui/icons";

let tempStorage = null;

export default function Year(props) {
  const {
    field,
    title,
    onUpdate,
    enableQuery,
    length,
    placeHolder,
    crfStatus,
    read_only,
  } = props;

  const [localVal, setLocalVal] = useState(field.value_obj.value || null);
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  useEffect(() => {
    setLocalVal(field.value_obj.value);
  }, [field.value_obj.value]);

  const closeModal = () => {
    setShowingCompleteChangeModal(false);
    setLocalVal(tempStorage);
  };

  const commonUpdateValueActions = (value) => {
    onUpdate({ value, value_name: field.value_obj.value_name });
  };

  const updateValue = (value) => {
    if (crfStatus === "data_complete") {
      tempStorage = localVal;
      setLocalVal(value);
      setShowingCompleteChangeModal(true);
      return;
    }
    setLocalVal(value);
    commonUpdateValueActions(value);
  };

  const acceptTempStorage = () => {
    commonUpdateValueActions(localVal);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget {...props} currentValueDisplay={localVal} />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>

        <div className={[styles["right-split"], styles.date_entry].join(" ")}>
          {read_only ? (
            <div>{localVal}</div>
          ) : (
            <YearUI
              {...props}
              field={{ value_obj: { ...field.value_obj, value: localVal } }}
              onUpdate={updateValue}
              error={"Out of range."}
            />
          )}
        </div>
        {/*}  <div>
          <CheckIcon fontSize="20px" />
        </div>*/}
      </div>

      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
