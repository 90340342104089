import React, { useState, useEffect } from "react";
import ModeCommentOutlined from "@mui/icons-material/ModeCommentOutlined";
import AddIcon from "@mui/icons-material/Add";
import styles from "./notification_widget.module.css";
import NotificationWindow from "./components/notification_window";
import PriorityHighIcon from "@mui/icons-material/PriorityHighRounded";
import DangerousIcon from "@mui/icons-material/DangerousOutlined";
import { useSelector } from "react-redux";
import { NotifyIcon } from "ui/icons";

const query_icon_open_style = [styles.query_icon].join(" ");
const query_icon_resolved_style = [
  styles.query_icon,
  styles.query_icon_resolved,
].join(" ");
const query_icon_responded_style = [
  styles.query_icon,
  styles.query_icon_responded,
].join(" ");

const NotificationWidget = function (props) {
  const {
    field,
    query = {},
    setQuery,
    title,
    crf_id,
    field_id,
    subject_id,
    domain_id,
    visit_index,
    calcUpdateToCrfStatus,
    currentValueDisplay,
    multi_entry_crf_id,
    form_type,
    crf_name,
    crf_slug,
  } = props;
  const permissions = useSelector((state) => state.user.userData.permissions);

  const [showing, setShowing] = useState(false);
  const [showingAdd, setShowingAdd] = useState(false);

  const closeNotificationWindow = () => {
    setShowing(false);
  };

  const _setShowingAdd = (value) => {
    if (permissions.includes("start_query")) {
      setShowingAdd(value);
    }
  };

  const get_query_icon_style = (query) => {
    if (query.status === "resolved") {
      return query_icon_resolved_style;
    } else if (
      (query.status === "responded" && permissions.includes("start_query")) ||
      query.status === "action"
    ) {
      return query_icon_open_style;
    } else if (
      (query.status === "responded" && permissions.includes("respond_query")) ||
      query.status === "okay"
    ) {
      return query_icon_responded_style;
    } else if (query.status === "open" && permissions.includes("start_query")) {
      return query_icon_responded_style;
    } else if (
      query.status === "open" &&
      permissions.includes("respond_query")
    ) {
      return query_icon_open_style;
    } else return "";
  };

  return (
    <div className={styles.top_wrapper}>
      {!query.id && showingAdd ? (
        <div className={styles.add_icon_wrapper}>
          <AddIcon
            className={styles.add_icon}
            sx={{ fontSize: "18px" }}
            onClick={() => setShowing(!showing)}
          />
        </div>
      ) : null}
      {query.id || showing ? (
        <div className={styles.query_icon_wrapper}>
          {query.id ? (
            <ModeCommentOutlined
              className={get_query_icon_style(query)}
              sx={{ fontSize: "16px" }}
              onClick={() => setShowing(!showing)}
            />
          ) : null}
          {showing ? (
            <NotificationWindow
              crf_name={crf_name}
              data_name={title}
              showing={showing}
              closeNotificationWindow={closeNotificationWindow}
              query={query}
              setQuery={setQuery}
              crf_id={crf_id}
              field_id={field_id}
              subject_id={subject_id}
              domain_id={domain_id}
              visit_index={visit_index}
              setQuery={setQuery}
              calcUpdateToCrfStatus={calcUpdateToCrfStatus}
              currentValueDisplay={currentValueDisplay}
              multi_entry_crf_id={multi_entry_crf_id}
              form_type={form_type}
              crf_slug={crf_slug}
            />
          ) : null}
        </div>
      ) : null}
      {field.value_obj.deviation ? (
        <div>
          <PriorityHighIcon
            className={styles.deviation_icon}
            sx={{ fontSize: "18px" }}
          />
        </div>
      ) : null}
      {field.value_obj.violation ? (
        <div>
          <DangerousIcon
            className={styles.violation_icon}
            sx={{ fontSize: "18px" }}
          />
        </div>
      ) : null}
      <div
        className={
          permissions.includes("start_query") || query.id
            ? styles.title_pointer
            : styles.title
        }
        onMouseEnter={() => _setShowingAdd(true)}
        onMouseLeave={() => setShowingAdd(false)}
        onClick={() => {
          if (
            permissions.includes("start_query") ||
            (query.id && permissions.includes("respond_query"))
          ) {
            setShowing(!showing);
          }
        }}
      >
        <div>
          {field.required ? <span className={styles.required}>*</span> : null}
          {title}
        </div>
      </div>
    </div>
  );
};

export default NotificationWidget;
