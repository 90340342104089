import React, { useState, useRef } from "react";
import Radio from "@mui/material/Radio";
import styles from "../radio_group.module.css";
import { cloneDeep } from "lodash";
import { TextField } from "@mui/material";

export default function RadioGroupUI(props) {
  const {
    field,
    key,
    options,
    onUpdate,
    orientation,
    value_position = "value",
    value_name_position = "value_name",
    value2_position = "value2",
    value2_name_position = "value2_name",
    key_prefix,
  } = props;

  const [localText, setLocalText] = useState(
    field.value_obj[value2_position] || "",
  );
  const ref = useRef(null);

  const updateValue = (newValue, clearFreeText) => {
    let obj = cloneDeep(field.value_obj);
    if (newValue === field.value_obj[value_position]) {
      obj[value_position] = "";
      obj[value2_position] = "";
      setLocalText("");
    } else {
      obj[value_position] = newValue;
      if (clearFreeText) {
        obj[value2_position] = "";
        setLocalText("");
      }
    }
    obj[value_name_position] =
      field[value_name_position] || field.value_obj[value2_name_position] || "";
    obj[value2_name_position] =
      field[value2_name_position] ||
      field.value_obj[value2_name_position] ||
      "";
    onUpdate(obj);
  };
  const updateValue2 = (newValue) => {
    let obj = cloneDeep(field.value_obj);
    if (newValue === field.value_obj[value_position]) {
      obj[value2_position] = "";
    } else {
      obj[value2_position] = newValue;
    }
    obj[value2_name_position] =
      field[value2_name_position] ||
      field.value_obj[value2_name_position] ||
      "";
    onUpdate(obj);
  };

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      ref.current.blur();
    }
  };

  const onBlur = () => {
    updateValue2(localText);
  };

  return (
    <div
      className={
        orientation === "vertical"
          ? styles.radio_group_vertical
          : styles.radio_group_flex
      }
    >
      {options
        ? options.map((o) => (
            <React.Fragment>
              <div
                className={styles.radio_item_wrapper}
                key={`${o.value}`}
                onClick={() => {
                  updateValue(o.value, !o.free_text);
                }}
              >
                <Radio
                  key={`${o.value}`}
                  size="small"
                  checked={o.value === field.value_obj[value_position] || false}
                  sx={{
                    padding: "0px",
                    marginRight: "6px",
                  }}
                />
                <div className={styles.radio_text}>{o.label}</div>
              </div>{" "}
              {(o.value === field.value_obj[value_position] || false) &&
              o.free_text ? (
                <div>
                  <TextField
                    inputRef={ref}
                    placeholder=""
                    value={localText}
                    onChange={(e) => setLocalText(e.target.value)}
                    autoComplete="off"
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "5px",
                        paddingLeft: "10px",
                        textAlign: "left",
                      },
                    }}
                    fullWidth
                  />
                </div>
              ) : null}
            </React.Fragment>
          ))
        : null}
    </div>
  );
}
