import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get, post } from "util/requests";
import colors from "colors";
import moment from "moment";
import styles from "./consent_view.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import DomainTag from "views/subjects/components/domain_tag";
import { useLocation } from "react-router-dom";
import ConsentUpload from "./components/consent_upload";
import { CheckIcon } from "ui/icons";

const render_type = (type) => {
  return (
    {
      deferred_waiver_consent: "Deferred",
      surrogate: "Surrogate",
      regained_capacity: "Regained Capacity",
      "2_physician_emerg": "2 Physician Emergency",
      patient: "Patient",
    }[type] || type
  );
};

const columns = [
  {
    field: "consent_type",
    headerName: "Type",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => render_type(row.consent_type),
  },
  {
    field: "consent_date",
    headerName: "Consent Date",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => {
      if (row.consent_date && row.consent_time) {
        return `${row.consent_date} ${row.consent_time}`;
      } else {
        return moment(row.created_on).format("yyyy-MMM-DD HH:mm");
      }
    },
  },
  {
    field: "domains",
    headerName: "Domains",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <React.Fragment>
        {(row.domains && (
          <div className={styles.domain_tile_wrapper}>
            {row.domains.map((d) => (
              <DomainTag text={d} />
            ))}
          </div>
        )) || <div />}
      </React.Fragment>
    ),
  },
  {
    field: "files",
    headerName: "Uploaded File",
    align: "center",
    headerAlign: "center",
    width: 150,
    renderCell: ({ row }) => (row.file_id ? <CheckIcon /> : null),
  },
  {
    field: "external_file_ref",
    headerName: "External Reference",
    align: "center",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "created_on",
    headerName: "Created On",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.created_on && moment(row.created_on).format("yyyy-MMM-DD HH:mm")) ||
      "",
  },
];

export function Table(props) {
  const {
    crf_id,
    subject_id,
    domain_id,
    updateCrfStatus,
    form_type,
    multi_entry_crf_id,
    data,
    onRowClick,
  } = props;

  return (
    <DataGrid
      rows={data}
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      components={{
        NoRowsOverlay: () => (
          <div className={styles.no_rows_overlay}>No Consent Records</div>
        ),
      }}
      onRowClick={(data) => {
        onRowClick(data.row);
      }}
    />
  );
}

export default function ConsentView(props) {
  const { crf_id, subject_id, domain_id, study_id } = props;
  const permissions = useSelector((state) => state.user.userData.permissions);

  const [data, setData] = useState([]);
  const location = useLocation();
  const [showingModal, setShowingModal] = useState(false);

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "consent_table",
      subject_id,
    });
  }, [location.pathname]);

  const fetch_table_data = () => {
    get(`/consent_table_view/${subject_id}`).then((ret) => setData(ret.data));
  };

  useEffect(() => {
    fetch_table_data();
  }, [crf_id, subject_id]);

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.stretch_header}>
          <div>
            <strong>Consent</strong>
          </div>
          {permissions.includes("consent_record_update") ? (
            <div>
              <LoadingButton
                variant="contained"
                loading={false}
                onClick={() => setShowingModal(true)}
              >
                <strong>ADD CONSENT</strong>
              </LoadingButton>
            </div>
          ) : null}
        </div>
      </div>

      <Table
        {...props}
        data={data}
        onRowClick={(data) => setShowingModal(data)}
      />
      <ConsentUpload
        subject_id={subject_id}
        showingModal={showingModal}
        setShowingModal={setShowingModal}
        RefetchTableData={fetch_table_data}
        study_id={study_id}
      />
    </div>
  );
}
