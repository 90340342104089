import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import styles from "../subjects.module.css";

export default function AllDomainsTable(props) {
  const { columns_all, study_id } = props;
  const [patientData, setPatientData] = useState([]);

  useEffect(() => {
    get(`/subjects/${study_id}`).then((ret) => setPatientData(ret.data));
  }, []);

  return (
    <DataGrid
      rows={patientData}
      columns={columns_all}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={15}
      rowsPerPageOptions={[15]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      autoPageSize
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 15,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={({ row }) => {
        router.navigate(
          `/study/${row.study_id}/site/${row.site_id}/subjects/${encodeURIComponent(row.id)}/view`,
        );
      }}
      components={{
        NoRowsOverlay: () => (
          <div className={styles.no_rows_overlay}>No Subjects Available</div>
        ),
      }}
    />
  );
}
